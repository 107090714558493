<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="!loading">
    <h6
      v-if="!protocolList || protocolList.length === 0"
      class="mb-4"
    >
      Es gibt noch keine Protokolle
    </h6>

    <div
      v-if="showLoadMore"
      class="container text-center mb-3 load-more"
    >
      <button
        class="btn hover-secondary text-primary"
        @click="loadMore"
      >
        - Mehr Protokolle anzeigen -
      </button>
    </div>

    <transition-group
      name="slide-fade"
      tag="div"
    >
      <ProtocolItem
        v-for="(protocol, index) in protocolList"
        :key="protocol._id"
        ref="protocolItem"
        :index="index"
        :protocol="protocol"
      />
    </transition-group>

    <button
      v-if="!protocolEditor"
      class="btn btn-primary"
      @click="createProtocol()"
    >
      <i class="far fa-comment-alt-lines" /> Protokoll erstellen
    </button>
    <div v-if="protocolEditor">
      <ProtocolForm
        :protocol-details="protocolDetails"
        @send="send"
      />
    </div>
  </div>
</template>

<script>
import ProtocolForm from './Forms/Protocol/ProtocolForm.vue'
import ProtocolItem from './Forms/Protocol/ProtocolItem.vue'

export default {
  name: 'Protocols',
  components: {
    ProtocolForm,
    ProtocolItem
  },
  props: {
    entityId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      protocolEditor: false,
      protocolList: [],
      protocolDetails: null,
      totalProtocols: 0,
      elementsLoaded: 0,
      visible: false
    }
  },
  computed: {
    showLoadMore () {
      return this.totalProtocols > 3 && this.totalProtocols > this.elementsLoaded
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        const protocols = await this.$store.dispatch('protocol/find', {
          query: {
            entityId: this.entityId,
            $limit: 3,
            $sort: {
              createdAt: -1
            }
          }
        })
        this.protocolList = protocols.data.reverse()
        this.totalProtocols = protocols.total
        this.elementsLoaded = protocols.data.length
      } catch (e) {
        console.error(e)
      }
    },
    async loadMore () {
      try {
        const protocols = (await this.$store.dispatch('protocol/find', {
          query: {
            entityId: this.entityId,
            $skip: this.elementsLoaded,
            $limit: 3,
            $sort: {
              createdAt: -1
            }
          }
        }))
        this.protocolList.unshift(...(protocols.data.reverse()))
        this.totalComments = protocols.total
        this.elementsLoaded += protocols.data.length
      } catch (error) {
        console.error(error)
      }
    },
    createProtocol () {
      this.protocolEditor = !this.protocolEditor
      this.protocolDetails = new this.$FeathersVuex.api.Protocol()
      this.protocolDetails.userId = this.$store.getters['auth/user'].user._id
      this.protocolDetails.entityId = this.entityId
    },
    async send (resubmitPayload) {
      try {
        if (resubmitPayload) {
          const savedResubmit = await resubmitPayload.save()
          this.protocolDetails.resubmitId = savedResubmit._id
        }
        await this.protocolDetails.save()
      } catch (e) {
        console.error(e)
      } finally {
        this.protocolEditor = !this.protocolEditor
        this.fetchData()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.load-more {
  color: inherit !important;

  a:hover {
    text-decoration: underline !important;
  }
}

.protocols ::v-deep{
  p, ul{
    margin-bottom: 0;
  }
}
</style>
