<template>
  <Modal
    title="Kundenkonto erstellen"
    @close="$emit('close')"
  >
    <template #body>
      <form>
        Geben Sie die E-Mail Adresse an, mit der das Kundenkonto erstellt werden soll.
        <BasicInput
          id="createCustomerAccount"
          v-model="customerAccountMail"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          name="E-Mail Kundenkontozugang"
          label="E-Mail Kundenkontozugang"
          placeholder="E-Mail Adresse"
          type="email"
          class="mb-4"
          required
        />
        <div class="text-right">
          <p
            v-if="accountCreationError"
            class="text-danger"
          >
            {{ accountCreationError }}
          </p>
          <p
            v-if="accountCreationSuccess"
            class="text-success"
          >
            {{ accountCreationSuccess }}
          </p>
        </div>
        <div class="form-row justify-content-end">
          <button
            type="button"
            class="btn btn-orange"
            :disabled="accountCreationPending"
            @click="createAccount"
          >
            <i
              v-if="accountCreationPending"
              class="fas fa-circle-notch fa-spin"
            />
            <i
              v-else
              class="far fa-user-plus"
            />
            Kundenkonto erstellen
          </button>
        </div>
      </form>
    </template>
  </Modal>
</template>
<script>
import feathersClient from '@/api'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import Modal from '@/components/Modal.vue'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    Modal,
    BasicInput
  },
  props: {
    customerId: {
      type: String,
      required: true
    },
    customerEmail: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      customerAccountMail: null,
      accountCreationPending: false,
      accountCreationError: null,
      accountCreationSuccess: null
    }
  },
  mounted () {
    if (this.customerEmail) this.customerAccountMail = this.customerEmail
  },
  methods: {
    async createAccount () {
      if (!await this.$validator.validateAll()) return
      try {
        this.accountCreationPending = true
        this.accountCreationError = null
        this.accountCreationSuccess = null
        await feathersClient.service('create-customer-account').create({ customerId: this.customerId, customerAccountMail: this.customerAccountMail })
        this.$emit('created')
      } catch (error) {
        console.error(error)
        this.accountCreationError = error.message
      } finally {
        this.accountCreationPending = false
      }
    }
  }
}
</script>
