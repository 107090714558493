<template>
  <div>
    <ResubmissionModal
      :show="showResubmitModal"
      @add="addResubmission"
      @close="closeModal"
    />
    <b-card
      no-body
      header
      class="mb-2"
    >
      <template #header>
        <div
          class="row"
        >
          <div class="col d-flex align-items-center">
            <h6 class="mb-0">
              Neues Protokoll
            </h6>
          </div>
          <div class="col-auto">
            <button
              v-if="showCreateResubmitBtn"
              class="btn btn-primary"
              @click="showResubmitModal = true"
            >
              <i class="far fa-clipboard-list-check" /> Wiedervorlage erstellen
            </button>
            <button
              v-else
              class="btn btn-secondary text-primary"
              @click="showResubmitModal = true"
            >
              <i class="far fa-pen" /> Wiedervorlage bearbeiten
            </button>
          </div>
        </div>
      </template>
      <b-card-body>
        <div class="form-group mb-0">
          <div class="form-row pb-3">
            <div class="col-12 col-md-4">
              Zusammenfassung
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-wysiwyg
                ref="summaryEditor"
                :file-upload="false"
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div class="form-row pb-3">
            <div class="col-12 col-md-4">
              Gesprächsergebnis
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-wysiwyg
                ref="resultEditor"
                :file-upload="false"
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div class="form-row pb-3">
            <div class="col-12 col-md-4">
              Nächster Schritt
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-wysiwyg
                ref="nextStepEditor"
                :file-upload="false"
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div class="form-row pb-3">
            <div class="col-12 col-md-4">
              Notizen
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-wysiwyg
                ref="notesEditor"
                :file-upload="false"
              />
            </div>
          </div>
        </div>

        <basic-file-upload
          v-model="files"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          multiple
          accept=".jpg,.jpeg,.png,.pdf"
          label="Anhänge hochladen"
          :align-label-center="false"
          @uploaded="addFile"
          @upload-pending="uploadPending = $event"
          @removed="removeFile"
        />
      </b-card-body>
      <b-card-footer class="bg-white">
        <div class="row">
          <div class="col">
            <button
              class="btn btn-primary float-right"
              type="button"
              @click="prepareSend()"
            >
              Speichern
            </button>
          </div>
        </div>
      </b-card-footer>
    </b-card>
  </div>
</template>

<script>
import ResubmissionModal from '@/components/Modals/ResubmissionModal.vue'
import BasicWysiwyg from '@/components/BaseComponents/BasicWysiwyg.vue'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload.vue'
import resubmitStatus from '@/resources/enums/resubmitStatus'
import moment from 'moment'

export default {
  name: 'ProtocolForm',
  components: {
    ResubmissionModal,
    BasicWysiwyg,
    BasicFileUpload
  },
  props: {
    protocolDetails: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    }
  },
  data () {
    return {
      resubmitStatus,
      files: [],
      resubmission: null,
      showResubmitModal: false,
      showCreateResubmitBtn: true,
      resubmissionAdded: false,
      time: null
    }
  },
  methods: {
    closeModal () {
      this.showResubmitModal = false
    },
    async addResubmission (resubmission) {
      if (!this.resubmissionAdded) {
        this.resubmission = resubmission
        this.resubmissionAdded = true
        this.showCreateResubmitBtn = false
        this.setStatus()
        this.closeModal()
      } else {
        this.closeModal()
      }
    },
    prepareSend () {
      this.protocolDetails.summary = this.$refs.summaryEditor.editor.getHTML()
      this.protocolDetails.result = this.$refs.resultEditor.editor.getHTML()
      this.protocolDetails.nextStep = this.$refs.nextStepEditor.editor.getHTML()
      this.protocolDetails.notes = this.$refs.notesEditor.editor.getHTML()
      this.$emit('send', this.resubmission)
    },
    setStatus () {
      const today = moment()
      const todayNoTime = moment(moment(today).format('DD-MM-YYYY'), 'DD-MM-YYYY')
      const dueDateNoTime = moment(moment(this.resubmission.dueDate).format('DD-MM-YYYY'), 'DD-MM-YYYY')

      if (moment(this.resubmission.dueDate).isBefore(today)) {
        this.resubmission.status = resubmitStatus.OVERDUE.api
      } else if (moment(dueDateNoTime).isSame(todayNoTime)) {
        this.resubmission.status = resubmitStatus.DUE.api
      } else {
        this.resubmission.status = resubmitStatus.SCHEDULED.api
      }
    },
    addFile (file) {
      this.protocolDetails.attachmentIds.push(...file.map(file => file._id))
    },
    removeFile (index) {
      this.protocolDetails.attachmentIds.splice(index, 1)
    }
  }
}
</script>
