<template>
  <modal
    v-if="show"
    ref="resubmitModal"
    size="md"
    title="Wiedervorlage erstellen"
    :closable="true"
    @close="$emit('close')"
  >
    <template #body>
      <form
        novalidate
        @submit.prevent
      >
        <basic-typeahead
          id="resubmission-userId"
          v-model="resubmission.userId"
          :query="searchUserByName"
          :input-formatter="userFormatter"
          :result-formatter="userFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Bearbeiter"
          :horizontal="true"
          label="Bearbeiter"
          required
          autocomplete
        />

        <basic-input
          v-model="resubmission.subject"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Betreff"
          :horizontal="true"
          label="Betreff"
          required
        />

        <basic-select
          v-model="resubmission.category"
          class="mb-3"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Kategorie auswählen"
          :options="humanCategoryOptions"
          :horizontal="true"
          :margin="false"
          label="Kategorie"
          required
        />

        <basic-typeahead
          id="resubmission-dialogUser"
          v-model="resubmission.dialogUser"
          :query="searchUserByName"
          :input-formatter="userFormatter"
          :result-formatter="userFormatter"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Gesprächspartner"
          :horizontal="true"
          :margin="false"
          label="Gesprächspartner"
          required
        />

        <div class="form-row my-3">
          <div class="col-12 col-md-8">
            <basic-datepicker
              v-model="resubmission.dueDate"
              :horizontal="true"
              :columns="{label: ['col-12', 'col-md-6'], input:['col-12', 'col-md-6']}"
              label="Fällig am"
              placeholder="TT.MM.JJJJ"
              :margin="false"
              required
            />
          </div>
          <div class="col-12 col-md-4">
            <div class="d-flex justify-content-center align-items-center">
              <basic-select
                v-model="timeHours"
                class="mb-3"
                :columns="{input:['col-12', 'col-md-12']}"
                placeholder="HH"
                :options="hours"
                :horizontal="true"
                :margin="false"
                required
              />
              <p class="mx-1">
                :
              </p>
              <basic-select
                v-model="timeMinutes"
                class="mb-3"
                :columns="{input:['col-12', 'col-md-12']}"
                placeholder="MM"
                :options="minutes"
                :horizontal="true"
                :margin="false"
                required
              />
            </div>
          </div>
        </div>

        <div class="form-group mb-0">
          <div class="form-row pb-3">
            <div class="col-12 col-md-4">
              Gesprächsgrund
            </div>
            <div
              class="col-12 col-md-8"
            >
              <basic-textarea
                v-model="resubmission.reason"
                placeholder="Gesprächsgrund"
                :columns="{label: ['col-12', 'col-md-8'], input:['col-12', 'col-md-4']}"
                :margin="false"
              />
            </div>
          </div>
        </div>
      </form>
    </template>
    <template
      #footer
    >
      <button
        class="btn btn-primary float-right ml-2"
        @click="prepareSend()"
      >
        Speichern
      </button>
      <button
        class="btn hover-gray-2 text-primary float-right"
        @click="$emit('close')"
      >
        Abbrechen
      </button>
    </template>
  </modal>
</template>

<script>
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea.vue'
import Modal from '@/components/Modal.vue'
import resubmitCategory from '@/resources/enums/resubmitCategory'
import moment from 'moment'

export default {
  name: 'ResubmissionModal',
  components: {
    BasicTypeahead,
    BasicInput,
    BasicSelect,
    BasicDatepicker,
    BasicTextarea,
    Modal
  },
  props: {
    show: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      resubmission: null,
      timeHours: null,
      timeMinutes: null,
      resubmitCategory
    }
  },
  computed: {
    humanCategoryOptions () {
      return Object.values(this.resubmitCategory).map(category => ({ label: category.human, value: category.api }))
    },
    hours () {
      return Array.from(Array(24).keys()).map(hours => ({ label: (hours < 10 ? '0' : '') + hours, value: (hours < 10 ? '0' : '') + hours }))
    },
    minutes () {
      return Array.from(Array(60).keys()).map(minutes => ({ label: (minutes < 10 ? '0' : '') + minutes, value: (minutes < 10 ? '0' : '') + minutes }))
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    fetchData () {
      this.resubmission = new this.$FeathersVuex.api.Resubmission()
    },
    prepareSend () {
      const date = moment(this.resubmission.dueDate).format('DD/MM/YYYY')
      const time = this.timeHours + ':' + this.timeMinutes
      const dateTime = moment(date + ' ' + time, 'DD/MM/YYYY HH:mm')
      this.resubmission.dueDate = dateTime.toDate()
      this.$emit('add', this.resubmission)
    },
    searchUserByName (name) {
      return this.$store.dispatch('users/find', {
        query: {
          $limit: 25,
          $or: [
            { lastname: { $regex: '^' + name, $options: 'i' } },
            { firstname: { $regex: '^' + name, $options: 'i' } }
          ]

        }
      })
    },
    userFormatter (user) {
      return `${user.lastname} ${user.firstname}`
    }
  }
}
</script>
