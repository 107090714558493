<template>
  <div>
    <CaHeader
      :loading="loading"
      :heading="fullname"
    >
      <template #alert>
        <transition name="slide-fade">
          <ca-alert
            v-if="!!createCustomeraccountSuccessMessage"
            variant="success"
            :message="createCustomeraccountSuccessMessage"
            @close="createCustomeraccountSuccessMessage = null"
          />
        </transition>
      </template>
      <template #buttonSpace>
        <KundenkontoErstellenModal
          v-if="kundenkontoErstellenModal"
          :customer-id="customer._id"
          :customer-email="customer && customer.contact && customer.contact.MAIL"
          @close="kundenkontoErstellenModal = false"
          @created="customerAccountCreated()"
        />
        <div>
          <router-link
            v-if="$can('addWiedervorlagen')"
            class="btn btn-primary mr-2"
            :to="{name: 'ResubmitCreate', params: {customerId: customer._id} }"
          >
            Neue Wiedervorlage erstellen
          </router-link>
          <router-link
            v-if="$can('editOwnCustomers') || $can('editAllCustomers')"
            class="btn btn-secondary text-primary mr-2"
            tag="button"
            :to="{ name: 'CustomerEdit', params: { customerId: customer._id } }"
          >
            <i class="far fa-pen" /> Bearbeiten
          </router-link>
          <button
            v-if="$can('createCustomerAccount')"
            class="btn btn-secondary text-primary mr-2"
            :disabled="customer.account"
            @click="kundenkontoErstellenModal = true"
          >
            <i
              v-if="accountCreationPending"
              class="fas fa-circle-notch fa-spin"
            />
            <i
              v-else
              class="far fa-user-plus"
            /> Kundenkonto erstellen
          </button>
          <button
            v-if="$can('neuabschluesseVerwalten') && !customer.neuabschlussGesperrt"
            class="btn btn-primary text-secondary mr-2 mt-2"
            @click="showNeuabschlussDoubleOptModal = true"
          >
            Neubaschluss sperren
          </button>
          <button
            v-if="$can('neuabschluesseVerwalten') && customer.neuabschlussGesperrt"
            class="btn btn-primary text-secondary mr-2 mt-2"
            @click="showNeuabschlussDoubleOptModal = true"
          >
            Neubaschluss freigeben
          </button>
        </div>
      </template>
    </CaHeader>
    <Modal
      v-if="$can('neuabschluesseVerwalten') && showNeuabschlussDoubleOptModal"
      :title="`Neuabschluss ${ customer.neuabschlussGesperrt ? 'freigeben' : 'sperren' }`"
      @close="showNeuabschlussDoubleOptModal = false"
    >
      <template #body>
        <p>Möchten Sie wirklich diesen Kunden für Neuabschlüsse {{ customer.neuabschlussGesperrt ? 'freigeben' : 'sperren' }}?</p>
        <div class="d-flex justify-content-between w-full">
          <button
            class="btn btn-orange text-secondary mr-2 mt-2"
            @click="showNeuabschlussDoubleOptModal = false"
          >
            Abbrechen
          </button>
          <button
            class="btn btn-success text-secondary mr-2 mt-2"
            type="submit"
            @click="customerNeuabschlussHandler()"
          >
            Bestätigen
          </button>
        </div>
      </template>
    </Modal>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading && customer"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <ueberpruefung
            v-if="$can('neuabschluesseVerwalten') && customer.neuabschlussGesperrt"
            message="Neuabschlüsse von Verträgen sind für diesen Kunden gesperrt!"
            class="mb-2"
          />
          <CaCard class="mb-3">
            <template #header>
              <h4 class="mb-0">
                Allgemein
              </h4>
            </template>
            <template #body>
              <h5>Name und Anschrift</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Name
                </div>
                <div
                  v-if="!isCompany"
                  class="col-12 col-md-8"
                >
                  {{ titleType[customer.name.ANR.toUpperCase()].human }} {{ fullname }}
                </div>
                <template v-else>
                  <div class="col-12 col-md-8">
                    {{ customer.name.NAM }}
                  </div>
                  <div class="col-12 col-md-4">
                    Adresszeile 1
                  </div>
                  <div class="col-12 col-md-8">
                    {{ customer.address.ZUSATZ1 || '-' }}
                  </div>
                  <div class="col-12 col-md-4">
                    Adresszeile 2
                  </div>
                  <div class="col-12 col-md-8">
                    {{ customer.address.ZUSATZ2 || '-' }}
                  </div>
                  <div class="col-12 col-md-4">
                    Adresszeile 3
                  </div>
                  <div class="col-12 col-md-8">
                    {{ customer.address.ZUSATZ3 || '-' }}
                  </div>
                </template>
                <div class="col-12 col-md-4">
                  Straße, Nr.
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.address.STR }}
                </div>
                <div class="col-12 col-md-4">
                  PLZ / Ort
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.address.PLZ }} {{ customer.address.ORT }}
                </div>
                <div class="col-12 col-md-4">
                  Land
                </div>
                <div
                  v-if="country"
                  class="col-12 col-md-8"
                >
                  {{ country.name }}
                </div>
              </div>
              <h5>Weitere Informationen</h5>
              <div class="row mb-3">
                <template v-if="!isCompany">
                  <div class="col-12 col-md-4">
                    Geboren am
                  </div>
                  <div class="col-12 col-md-8">
                    {{ formatDate(customer.misc.GEBDAT, 'DD.MM.YYYY') }}
                  </div>
                  <template v-if="customer.misc.VERSTORBAM">
                    <div class="col-12 col-md-4">
                      Verstorben am
                    </div>
                    <div class="col-12 col-md-8">
                      {{ formatDate(customer.misc.VERSTORBAM, 'DD.MM.YYYY') }}
                    </div>
                  </template>
                </template>
              </div>
              <h5>Kontaktdaten</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Telefonnummer
                </div>
                <div
                  v-if="!isCompany"
                  class="col-12 col-md-8"
                >
                  {{ customer.contact.TEL || '-' }}
                </div>
                <div
                  v-else
                  class="col-12 col-md-8"
                >
                  {{ customer.contact.TEF || '-' }}
                </div>
                <div class="col-12 col-md-4">
                  Telefax
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.contact.FAX || '-' }}
                </div>
                <div class="col-12 col-md-4">
                  Mobiltelefon
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.contact.MOB || '-' }}
                </div>
                <div class="col-12 col-md-4">
                  E-Mail
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.contact.MAIL || '-' }}
                </div>
                <div class="col-12 col-md-4">
                  Website
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.misc.URL || '-' }}
                </div>
              </div>
            </template>
          </CaCard>
        </div>
        <div class="col-12 col-xl-4">
          <RelatedOffers
            v-if="orders"
            :orders="orders"
            :customer="customer"
          />
        </div>
      </div>
      <div
        v-if="!loading"
        class="row"
      >
        <div class="col-12 col-xl-8 col-xxl-6">
          <b-tabs class="tabs">
            <b-tab
              v-if="customer && me.role === 10"
              title="Protokolle"
              active
            >
              <div class="bg-white border border-gray-2 border-top-0 rounded-bottom mb-3">
                <div class="card-body">
                  <Protocols :entity-id="customer._id" />
                </div>
              </div>
            </b-tab>
            <Comments
              entity="customer"
              :entity-id="customer._id"
            />
          </b-tabs>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import CaCard from '@/components/Card'
import formatDate from '@/filters/formatDate.js'
import getCountryName from '@/helpers/getCountryName.js'
import familienstand from '@/resources/enums/familienstand.js'
import titleType from '@/resources/enums/titles/titleType.js'
import Protocols from '@/components/Protocols.vue'
import RelatedOffers from '@/components/RelatedOffers.vue'
import customerStatus from '@/resources/enums/customerStatus.js'
import klasse from '@/resources/enums/customerClasses.js'
import getFullNameFromCustomer from '@/resources/helpers/getFullNameFromCustomer'
import Comments from '@/components/Comments.vue'
import KundenkontoErstellenModal from './components/KundenkontoErstellenModal.vue'
import Modal from '@/components/Modal.vue'
import Ueberpruefung from '@/components/Forms/Order/Ueberpruefung.vue'

export default {
  components: {
    CaHeader,
    CaCard,
    Protocols,
    RelatedOffers,
    Comments,
    KundenkontoErstellenModal,
    Modal,
    Ueberpruefung
  },
  data () {
    return {
      loading: false,
      customer: null,
      country: null,
      orders: null,
      familienstand,
      titleType,
      customerStatus,
      klasse,
      accountCreationPending: false,
      accountCreationError: null,
      accountCreationSuccess: null,
      kundenkontoErstellenModal: false,
      createCustomeraccountSuccessMessage: null,
      showNeuabschlussDoubleOptModal: false
    }
  },
  computed: {
    isCompany () {
      if (!this.customer) return
      return this.customer.name.ANR === 'f'
    },
    me () {
      return this.$store.getters['auth/user']
    },
    fullname () {
      if (!this.customer) return
      return getFullNameFromCustomer(this.customer)
    }
  },
  created () {
    this.fetchData()
  },
  methods: {
    formatDate,
    getCountryName,
    async fetchData () {
      try {
        this.loading = true
        this.customer = await this.$store.dispatch('customer/get', this.$route.params.customerId)
        if (!this.customer.misc) this.$set(this.customer, 'misc', {})
        if (this.customer && this.customer.address.NAT) this.country = await this.$store.dispatch('countries/get', this.customer.address.NAT)
        this.orders = (await this.$store.dispatch('hs-order/find', {
          query: {
            customerId: this.customer._id
          }
        })).data
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    customerAccountCreated () {
      this.kundenkontoErstellenModal = false
      this.createCustomeraccountSuccessMessage = 'Kundenkonto erfolgreich angelegt.'
    },
    async customerNeuabschlussHandler () {
      this.error = null
      try {
        this.showNeuabschlussDoubleOptModal = false
        await this.$store.dispatch('customer/patch', [this.customer._id, { neuabschlussGesperrt: !this.customer.neuabschlussGesperrt }])
      } catch (e) {
        console.error(e)
        this.erorr = 'Fehler beim bearbeiten des Neuabschlusses'
      }
    }
  }
}
</script>

<style scoped lang="scss">

.site-header{
  z-index: 1020;
}

</style>
