<template>
  <!-- eslint-disable vue/no-v-html -->
  <div class="protocols">
    <b-card
      no-body
      header
      class="mb-2"
    >
      <template #header>
        <div
          class="row"
        >
          <div class="col d-flex align-items-center">
            <p class="d-inline-block font-weight-bold text-right small">
              {{ protocol.user.firstname }} {{ protocol.user.lastname }}
            </p>
            <p class="d-inline text-gray-4 small mb-0">
              - {{ formatTime(protocol.createdAt) }}
            </p>
          </div>
          <div class="col-auto d-flex justify-content-center align-items-center">
            <div v-if="protocol.resubmitId">
              <router-link
                class="btn bg-transparent text-primary p-0"
                tag="button"
                :to="{name: 'ResubmitDetail', params: {resubmitId: protocol.resubmitId}}"
              >
                <i class="far fa-clipboard-list-check" /> Wiedervorlage
              </router-link>
            </div>
            <span
              v-if="protocol.resubmitId && protocol.attachments.length > 0"
              class="divider mx-3"
            />
            <div
              v-if="protocol.attachments.length > 0"
            >
              <b-dropdown
                right
                variant="link"
                toggle-class="p-0"
              >
                <template #button-content>
                  <i class="far fa-paperclip" /> Anhänge
                </template>

                <b-dropdown-item
                  v-for="attachment in protocol.attachments"
                  :key="attachment._id"
                >
                  <SignedDownloadLink
                    :key="attachment._id"
                    :file="attachment"
                  >
                    <template #default="{link}">
                      <a
                        :href="link"
                        @click.stop
                      ><i class="far fa-file-pdf" /> {{ attachment.name }}</a>
                    </template>
                  </SignedDownloadLink>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </template>
      <b-card-body>
        <div class="row">
          <div class="col-12 col-md-4">
            Zusammenfassung
          </div>
          <div class="col-12 col-md-8">
            <div
              class="bg-gray-1 rounded p-3 mb-2"
            >
              <p v-html="protocol.summary" />
            </div>
          </div>
        </div>
        <b-collapse
          id="protocolDetails"
          v-model="visible"
        >
          <div
            v-if="protocol.result !== '<p></p>'"
            class="row py-3"
          >
            <div class="col-12 col-md-4">
              Gesprächsergebnis
            </div>
            <div class="col-12 col-md-8">
              <div
                class="bg-gray-1 rounded p-3 mb-2"
              >
                <p v-html="protocol.result" />
              </div>
            </div>
          </div>
          <div
            v-if="protocol.nextStep !== '<p></p>'"
            class="row pb-3"
          >
            <div class="col-12 col-md-4">
              Nächster Schritt
            </div>
            <div class="col-12 col-md-8">
              <div
                class="bg-gray-1 rounded p-3 mb-2"
              >
                <p v-html="protocol.nextStep" />
              </div>
            </div>
          </div>
          <div
            v-if="protocol.notes !== '<p></p>'"
            class="row"
          >
            <div class="col-12 col-md-4">
              Notizen
            </div>
            <div class="col-12 col-md-8">
              <div
                class="bg-gray-1 rounded p-3 mb-2"
              >
                <p v-html="protocol.notes" />
              </div>
            </div>
          </div>
        </b-collapse>
      </b-card-body>
      <b-card-footer
        v-if="(protocol.result || protocol.nextStep || protocol.notes) !== '<p></p>'"
        class="bg-transparent"
      >
        <div
          class="row"
          @click="visible = !visible"
        >
          <div class="col d-flex align-items-center">
            <p class="small">
              Gesprächsergebnis, Nächste Schritte und Notizen
            </p>
          </div>
          <div class="col-auto">
            <button
              class="btn bg-transparent p-0"
            >
              <i
                class="far fa-fw"
                :class="{'fa-chevron-up': visible, 'fa-chevron-left': !visible}"
              />
            </button>
          </div>
        </div>
      </b-card-footer>
    </b-card>
    <hr class="my-4">
  </div>
</template>
<script>
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'
import formatDate from '@/filters/formatDate.js'
import moment from 'moment'

export default {
  name: 'ProtocolItem',
  components: {
    SignedDownloadLink
  },
  props: {
    protocol: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      visible: false
    }
  },
  methods: {
    formatTime (myDate) {
      const today = moment()
      if (moment(myDate).isSame(today, 'day')) {
        return `Heute ${formatDate(myDate, 'HH:mm')}`
      } else {
        return formatDate(myDate, 'DD.MM.YYYY HH:mm')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.load-more {
  color: inherit !important;

  a:hover {
    text-decoration: underline !important;
  }
}

.protocols ::v-deep{
  p, ul{
    margin-bottom: 0;
  }
}
</style>
